import {Routes} from '@angular/router';
import {ROUTE} from "./talenlio-hireos/home-layout/common/constant/routing.constant";
import {AuthGuard} from "./talenlio-hireos/authentication/common/service/auth/auth.gaurd";

export const routes: Routes = [
    {
        path: '',
        data: {
            name: ROUTE.DASHBOARD
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./talenlio-hireos/home-layout/home-layout.route')
            .then(route => route.HOME_LAYOUT_ROUTES)
    },
    {
        path: ROUTE.AUTH,
        data: {
            name: ROUTE.AUTH
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./talenlio-hireos/authentication/authentication.route').then(route => route.AUTHENTICATION_ROUTES),
    },
    {
        path: ROUTE.SUPPORT,
        data: {
            name: ROUTE.AUTH
        },
        canActivate: [AuthGuard],
        loadChildren: () => import('./talenlio-hireos/authentication/support-layout/support-layout.route').then(route => route.SUPPORT_LAYOUT_ROUTES),
    },
    {
        path: ROUTE.WILDCARD,
        redirectTo: '',
    }
];
