import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthStore} from "../../../../home-layout/common/store/auth-store.service";
import {ROUTE} from "../../../../home-layout/common/constant/routing.constant";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(private authStore: AuthStore,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
        if (this.authStore.isLoggedIn()) {
            if (route.data['name'] && route.data['name'] === 'auth') {
                return this.router.createUrlTree([ROUTE.SLASH + ROUTE.DASHBOARD]);
            }
            return true;
        } else if (route.data['name'] && route.data['name'] === 'auth') {
            return true;
        } else {
            return this.gotoLoginPage(state.url);
        }
    }

    private gotoLoginPage(redirectUrl: string): UrlTree {
        return this.router.createUrlTree(
            [ROUTE.SLASH + ROUTE.AUTH + ROUTE.SLASH + ROUTE.LOGIN],
            {queryParams: {redirectUrl}}
        );
    }
}
