import {Component, OnInit} from '@angular/core';
import {RouterModule, RouterOutlet} from '@angular/router';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RequestInterceptor} from "./talenlio-hireos/authentication/common/service/auth/request-interceptor";
import {CommonModule} from "@angular/common";
import {GoogleMapLoaderService} from "./talenlio-hireos/common/service/google-map-loader.service";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule
    ],
    providers: [
        {
            //auth
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        }
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    title = 'Talenlio - HireOs';

    constructor(private googleMapLoaderService: GoogleMapLoaderService) {
    }

    ngOnInit(): void {
        this.loadGoogleMapsScript();
    }

    private loadGoogleMapsScript(): void {
        if (!document.querySelector('script[src^="https://maps.googleapis.com"]')) {
            let script = document.createElement('script');
            script = this.googleMapLoaderService.getScriptWithAttributes(script);
            this.googleMapLoaderService.handleOnload(script);
            this.googleMapLoaderService.handleOnError(script);
            document.head.appendChild(script);
        }
    }
}
